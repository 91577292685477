import React from "react";
import styled from "styled-components";

type Props = {};

const Root = styled.section`
  grid-area: hero2;
  position: relative;
  background-color: #ff81cb;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  max-width: 720px;
  padding-bottom: 400px;

  @media (min-width: 900px) {
    padding-bottom: 0;
    justify-content: flex-end;
  }
`;

const Img = styled.img`
  object-fit: contain;
`;

const Item = styled.div`
  position: absolute;
  border: solid 4px #000;
  border-bottom-width: 8px;
  border-right-width: 8px;
  background-color: #fff;
  padding: 32px 40px;
  transform: translate(-50%, -50%);

  &.web {
    top: 65%;
    left: 40%;
  }

  &.mobile {
    top: 76%;
    left: 60%;
  }

  &.startups {
    top: 89%;
    left: 40%;
  }

  @media (min-width: 900px) {
    &.web {
      top: 19%;
      left: 40px;
    }

    &.mobile {
      top: 74%;
      left: 62px;
    }

    &.startups {
      top: 86%;
      left: 73%;
    }
  }
`;

const HeroTwo: React.FC<Props> = ({}: Props) => {
  return (
    <Root>
      <Inner>
        <Img src="/hero.png" alt="" />
      </Inner>

      <Item className="web">Web</Item>
      <Item className="mobile">Mobile</Item>
      <Item className="startups">Startups</Item>
    </Root>
  );
};

export default HeroTwo;
