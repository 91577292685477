import React from "react";
import styled from "styled-components";

type Props = { type?: "button" | "submit" };

const Button = styled.button`
  background-color: #57ef98;
  border: solid 4px #000;
  border-left-width: 8px;
  border-top-width: 8px;
  font-size: 28px;
  padding: 32px 40px;
  cursor: pointer;
`;

const ContactButton: React.FC<Props> = ({ type = "button" }: Props) => {
  return (
    <Button className="contact-btn" type={type}>
      Contact Me
    </Button>
  );
};

export default ContactButton;
