import React, { useState } from "react";
import styled, { css } from "styled-components";
import ContactButton from "./ContactButton";

type Props = {};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 40px;
  gap: 40px;
`;

const H1 = styled.h1`
  font-size: 54px;
  text-align: center;
  max-width: 450px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #fff500;
  padding: 40px;
  border-radius: 4px;
  width: 80%;
  max-width: 900px;

  .contact-btn {
    margin-left: auto;
  }
`;

const InputStyles = css`
  padding: 28px 20px;
  border: solid 4px #000;
  border-bottom-width: 8px;
  border-right-width: 8px;
  border-radius: 4px;
  color: #000;
  background-color: #fffff4;
  font-size: 24px;

  &::placeholder {
    color: #000;
    font-size: 24px;
  }
`;

const Input = styled.input`
  ${InputStyles};
`;

const TextArea = styled.textarea`
  ${InputStyles};
  resize: none;
  height: 250px;
`;

const initialFormData = {
  name: "",
  email: "",
  extra: "",
};

const Contact: React.FC<Props> = ({}: Props) => {
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({ ...prev, [key]: event.target.value }));
  };

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      const response = await fetch(
        "https://p8q9t99sqd.execute-api.eu-west-1.amazonaws.com/default/helshJSemail",
        {
          method: "POST",
          body: JSON.stringify(formData),
        }
      );
      console.log(response);
      setFormData({ ...initialFormData });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Root id="contact">
      <H1>Let us share some thoughts</H1>

      <Form onSubmit={handleSubmit}>
        <Input
          name="name"
          placeholder="What is your name?"
          value={formData.name}
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange("name", event)
          }
        />
        <Input
          name="email"
          placeholder="On what email can we reach you?"
          value={formData.email}
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange("email", event)
          }
        />
        <TextArea
          name="extra"
          placeholder="What would you like to share?"
          value={formData.extra}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange("extra", event)
          }
        />
        <ContactButton type="submit" />
      </Form>
    </Root>
  );
};

export default Contact;
