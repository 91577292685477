import React from "react";
import styled from "styled-components";

const Root = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
  row-gap: 8px;
  flex-wrap: wrap;
  font-size: 12px;
  padding-bottom: 12px;

  address {
    font-style: normal;
  }
`;

const Footer: React.FC = () => {
  return (
    <Root>
      <span>Helsh-js BV</span>
      <span>BE 0717.812.668</span>
      <address>Brandveld 15, 9500 Geraardsbergen</address>
      <address>info@helsh-js.com</address>
    </Root>
  );
};

export default Footer;
