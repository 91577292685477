import React from "react";
import ContactButton from "./ContactButton";
import styled from "styled-components";

type Props = {};

const Root = styled.section`
  grid-area: hero1;
  background-color: #d661ff;
  padding: 48px 24px;
  border: solid 6px #000;
  display: flex;
`;

const Inner = styled.div`
  max-width: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  margin: auto;

  .contact-btn {
    margin-left: -33%;
  }
`;

const H1 = styled.h1`
  font-weight: bold;
  font-size: 54px;
`;

const H3 = styled.h3`
  font-weight: 600;
  font-size: 28px;
`;

const P = styled.p`
  font-size: 20px;
`;

const HeroOne: React.FC<Props> = ({}: Props) => {
  return (
    <Root>
      <Inner>
        <H1>Software Developer that gets the job done</H1>
        <H3>Think it, build it, ship it</H3>
        <P>
          I have the ability to transform an idea into a digital solution. The
          current technology makes the craziest things possible, so let’s get
          started.
        </P>
        <a href="#contact">
          <ContactButton />
        </a>
      </Inner>
    </Root>
  );
};

export default HeroOne;
