import React from "react";
import ReactDOM from "react-dom/client";
import styled, { createGlobalStyle } from "styled-components";
import HeroOne from "./HeroOne";
import HeroTwo from "./HeroTwo";
import Contact from "./Contact";
import Footer from "./Footer";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Oxanium", "Roboto", "Lucida Grande", "DejaVu Sans", "Bitstream Vera Sans", Verdana, Arial, sans-serif;
    background-color: #FFFFF4;
    min-height: 100vh;

    @font-face {
      font-family: "Oxanium";
      src: url("/oxanium/Oxanium-Regular.ttf"),
      url("/oxanium/Oxanium-Bold.ttf"),
      url("/oxanium/Oxanium-ExtraBold.ttf"),
      url("/oxanium/Oxanium-ExtraLight.ttf"),
      url("/oxanium/Oxanium-Light.ttf"),
      url("/oxanium/Oxanium-Medium.ttf"),
      url("/oxanium/Oxanium-SemiBold.ttf");
    }

    * {
      margin: 0;
      padding: 0;
    }

    /* width */

    ::-webkit-scrollbar {
      width: 12px;
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      background: #00fff6;
      border-radius: 4px;
    }
  }
`;

const Body = styled.section`
  letter-spacing: 3px;
`;

const Name = styled.h1`
  font-size: 24px;
  font-weight: normal;
  padding: 20px;
  letter-spacing: 0;
`;

const Hero = styled.section`
  display: grid;
  grid-template-areas: "hero1" "hero2";
  min-height: calc(100vh - 70px);
  width: 100%;

  @media (min-width: 900px) {
    grid-template-areas: "hero1 hero2";
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  }
`;

const Main = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`;

root.render(
  <React.StrictMode>
    <GlobalStyle />

    <Body>
      <header>
        <Name>Helsh js</Name>
      </header>

      <Main>
        <Hero>
          <HeroOne />
          <HeroTwo />
        </Hero>
        <Contact />
      </Main>
      <Footer />
    </Body>
  </React.StrictMode>
);
